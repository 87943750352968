// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0


export const appConfig = {
  apiGatewayInvokeUrl:
    "https://wxv35bz379.execute-api.us-east-1.amazonaws.com/Stage/",
  // cognitoUserPoolId: "us-east-1_yWDXLp8hl",
  // cognitoAppClientId: "7kc1i6ibd1d285cnli4bth2jbc",
  cognitoIdentityPoolId: "us-east-1:755aeae5-02b3-432a-8b1b-37e9a8616007",
  appInstanceArn:
    "arn:aws:chime:us-east-1:617328643005:app-instance/b730d2b3-cd03-43cf-9797-48d3a0111757",
  region: "us-east-1", // Only supported region for Amazon Chime SDK Messaging as of this writing
  attachments_s3_bucket_name: "aha-preprod1-chatattachmentsbucket-1omtdg9sr9ymk",
  cognitoUserPoolId: "us-east-1_hcuCkv23B",
  cognitoAppClientId: "5rfcj9pdicuur0bef0lnh7c8pr",
  // apiGatewayInvokeUrl:
  //   "https://9ud56i55u1.execute-api.us-east-1.amazonaws.com/Stage/",
  // cognitoAppClientId: "4g2c6q1ce3234hlrk1vt8ouiaa",
  // cognitoIdentityPoolId: "us-east-1:d97e207d-0ec6-4620-8862-0e0a5bd3cd00",
  // appInstanceArn:
  //   "arn:aws:chime:us-east-1:617328643005:app-instance/5969d448-bd0c-46b2-aeef-c322fa641a52",
  // region: "us-east-1", // Only supported region for Amazon Chime SDK Messaging as of this writing
  // attachments_s3_bucket_name: "aha-test-chatattachmentsbucket-1nxq37c4nguq3",
  // cognitoUserPoolId: "us-east-1_0jm1ZuFyR",
};

