import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import navlogoicon from "../../assets/images/aha-mainnav-footer-icon.svg";
import navlogoicontext from "../../assets/images/aha-mainnav-footer-icon-withtext.svg";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { CARE_AT_HOME_SCHEDULE, FACILITYLIST, HOMEHEALTH, HOSPITALLIST, LABLIST, ROLE_ADMIN, ROLE_SUPER_ADMIN, routes, ROUTINEREDRAW, SCHEDULE, STATORDER, TERRITORYLIST, TIMEDDRAW, TRACKER, USER_GUIDE, USERLIST, ZONELIST, SEARCH } from "../../app/utils/Constants";
import { useChatChannelState, useChatMessagingState } from "../../features/Chat/providers/ChatMessagesProvider";
import { isOpenReportVal, setIsOpenReport } from "../../appSlice";
import { useDispatch, useSelector } from "react-redux";
// import { clearAll } from "../../app/services/aha-api";

export default function InnerNavigation() {
  const DESKTOP_APP_VERSION = process.env.REACT_APP_DESKTOP_APP_VERSION;
  const history = useHistory();
  const dispatch = useDispatch();
  const [channels, setChannels] = React.useState([]);
  const { sidePanel, setSidePanel, setActiveChannel, activeChannelRef, setActiveChannelMemberships } = useChatChannelState();
  const { setMessages } = useChatMessagingState();
  const isOpenReport = useSelector(isOpenReportVal);

  const searchObj = window.localStorage.getItem(`globalSearch`)
    ? JSON.parse(window.localStorage.getItem(`globalSearch`)) || {}
    : {};

  const { selectedEntities = [], text = "" } = searchObj;


  const [toggled, setToggled] = useState(false);

  let roles =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles) ||
    [];

  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];

  // user-permission
  const userPermission = permission.filter((perm) => perm.page === "users");
  const access = userPermission.length === 1 ? userPermission[0].access : [];
  const canViewUsers =
    access && access.find((item) => item === "list") === "list" ? true : false;

  // facility-permission
  const facilityPermission = permission.filter(
    (perm) => perm.page === "facilities"
  );
  const facilityAccess =
    facilityPermission.length === 1 ? facilityPermission[0].access : [];
  const canViewFacilities =
    facilityAccess && facilityAccess.find((item) => item === "list") === "list"
      ? true
      : false;

  // hospital-permission
  const hospitalPermission = permission.filter(
    (perm) => perm.page === "hospitals"
  );
  const hospitalAccess =
    hospitalPermission.length === 1 ? hospitalPermission[0].access : [];
  const canViewHospitals =
    hospitalAccess && hospitalAccess.find((item) => item === "list") === "list"
      ? true
      : false;


  // lab-permission
  const labPermission = permission.filter(
    (perm) => perm.page === "labs"
  );
  const labAccess =
    labPermission.length === 1 ? labPermission[0].access : [];
  const canViewLabs =
    labAccess && labAccess.find((item) => item === "list") === "list"
      ? true
      : false;

  // territory-permission
  const territoryPermission = permission.filter(
    (perm) => perm.page === "territories"
  );
  const territoryAccess =
    territoryPermission.length === 1 ? territoryPermission[0].access : [];
  const canViewTerritories =
    territoryAccess && territoryAccess.find((item) => item === "list") === "list"
      ? true
      : false;

  // zone-permission
  const zonePermission = permission.filter(
    (perm) => perm.page === "zones"
  );
  const zoneAccess =
    zonePermission.length === 1 ? zonePermission[0].access : [];
  const canViewZones =
    zoneAccess && zoneAccess.find((item) => item === "list") === "list"
      ? true
      : false;

  // stat-orders-permission
  const statOrdersPermission = permission.filter(
    (perm) => perm.page === "orders"
  );
  const statAccess =
    statOrdersPermission.length === 1 ? statOrdersPermission[0].access : [];
  const canViewStatPages =
    statAccess && statAccess.find((item) => item === "list") === "list" ? true : false;

  // home-health-permission
  const homeHealthOrdersPermission = permission.filter(
    (perm) => perm.page === "home-health-orders"
  );
  const homeHealthAccess =
    homeHealthOrdersPermission.length === 1 ? homeHealthOrdersPermission[0].access : [];
  const canViewHomeHealth =
    homeHealthAccess && homeHealthAccess.find((item) => item === "list") === "list" ? true : false;

  const canViewCareAtHomeScheduler =
    homeHealthAccess && homeHealthAccess.find((item) => item === "schedule") === "schedule" ? true : false;

  // map-permission
  const mapPermission = permission.filter(
    (perm) => perm.page === "map"
  );
  const mapAccess =
    mapPermission.length === 1 ? mapPermission[0].access : [];
  const canViewMap =
    mapAccess && mapAccess.find((item) => item === "layers") === "layers" ? true : false;


  // globalsearch-permission
  const gloablSearchPermission = permission.filter(
    (perm) => perm.page === "cloudsearch"
  );
  const gloablSearchAccess =
    gloablSearchPermission.length === 1 ? gloablSearchPermission[0].access : [];
  const canViewGlobalSearch =
    gloablSearchAccess && gloablSearchAccess.find((item) => item === "search") === "search" ? true : false;


  // schedule-permission
  const schedulePermission = permission.filter(
    (perm) => perm.page === "events"
  );
  const scheduleAccess =
    schedulePermission.length === 1 ? schedulePermission[0].access : [];
  const canViewScheduler =
    scheduleAccess && scheduleAccess.find((item) => item === "list") === "list" ? true : false;

  const canViewAdminPages = canViewUsers || canViewFacilities || canViewHospitals || canViewLabs || canViewTerritories || canViewZones;

  let canViewAdminChat = roles && roles.includes(ROLE_SUPER_ADMIN);
  let currentPath = window.location.pathname;

  const handleChangeMenu = (path) => {
    let newPath = path;
    if (currentPath !== path) {
      if (path === SCHEDULE) {
        const username = localStorage.getItem("username");
        const localScheduleData = window.localStorage.getItem(`${username}.scheduleFilter`)
          ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
          : {};
        const { selectedDate = "" } = localScheduleData || {};
        if (_.isEmpty(selectedDate)) {
          localStorage.setItem(
            `${username}.scheduleFilter`,
            JSON.stringify({ ...localScheduleData, selectedDate: new Date() })
          );
        }
        // clearAll()
      } else if (path === routes.CHAT || path === routes.CHAT_ADMIN) {
        setActiveChannel({});
        setMessages([]);
        setActiveChannelMemberships([]);
        activeChannelRef.current = null;
      } else if (path === SEARCH) {
        if (text) {
          newPath = `${newPath}?search_input=${text}`;
          if (selectedEntities && selectedEntities.length) {
            newPath = `${newPath}&entity=${selectedEntities.map(ent => ent.value).join(",")}`
          }
        }
      } else if (path === CARE_AT_HOME_SCHEDULE) {
        const username = localStorage.getItem("username");
        const localScheduleData = window.localStorage.getItem(`${username}.homeHealthScheduleFilter`)
          ? JSON.parse(window.localStorage.getItem(`${username}.homeHealthScheduleFilter`))
          : {};
        const { selectedDate = "" } = localScheduleData || {};
        if (_.isEmpty(selectedDate)) {
          localStorage.setItem(
            `${username}.homeHealthScheduleFilter`,
            JSON.stringify({ ...localScheduleData, selectedDate: new Date() })
          );
        }
      }
      history.push(newPath);
    }
  }

  const handleChannel = (channels) => {
    let filter = channels.filter((val) => {
      return (
        val.LastMessageTimestamp !== null &&
        val.ReadMarkerTimestamp !== null &&
        val.ReadMarkerTimestamp < val.LastMessageTimestamp
      );
    });
    let newFilter = channels.filter((val) => {
      return (
        val.LastMessageTimestamp !== null && val.ReadMarkerTimestamp === null
      );
    });
    if (filter.length > 0) {
      setSidePanel(true);
    }
    if (newFilter.length > 0) {
      setSidePanel(true);
    }
    if (filter.length === 0 && newFilter.length === 0) {
      setSidePanel(false);
    }
  };

  React.useEffect(() => {
    handleChannel(channels);
  }, [channels]);
  return (
    <React.Fragment>
      <ProSidebar className={toggled ? "nav-expanded" : ""} collapsed="true">
        <SidebarHeader />
        <SidebarContent>
          <button className="nav-expand-collapse-btn" onClick={() => setToggled(!toggled)}><span className={!toggled ? "menu-open" : "menu-close close-icon"}> <span className="visually-hidden">Menu collapse/Expand button</span></span></button>
          <Menu>
            {canViewMap && <MenuItem
              className={currentPath === TRACKER ? "active" : ""}
              onClick={() => handleChangeMenu(TRACKER)}
              title="Tracker"
            >
              <span className="navIcon navMaps">
                <span className="visually-hidden">Tracker Icon</span>
              </span>
              <span className="pl-20">Tracker</span>
            </MenuItem>}
            {canViewScheduler && <MenuItem
              className={currentPath === SCHEDULE ? "active" : ""}
              onClick={() => handleChangeMenu(SCHEDULE)}
              title="Schedule"
            >
              <span className="navIcon navSchedule">
                <span className="visually-hidden">Schedule Icon</span>
              </span>
              <span className="pl-20">Schedule</span>
            </MenuItem>}
            {canViewStatPages && <MenuItem
              className={currentPath.startsWith(STATORDER) || currentPath.startsWith(ROUTINEREDRAW) || currentPath.startsWith(TIMEDDRAW) ? "active" : ""}
              onClick={() => handleChangeMenu(STATORDER)}
              title="Stat Order"
            >
              <span className="navIcon navStatOrder">
                <span className="visually-hidden">Stat Order Icon</span>
              </span>
              <span className="pl-20">Stat Order</span>
            </MenuItem>}
            {canViewHomeHealth && <MenuItem
              className={currentPath.startsWith(HOMEHEALTH) && currentPath !== CARE_AT_HOME_SCHEDULE ? "active" : ""}
              onClick={() => handleChangeMenu(HOMEHEALTH)}
              title="Care at Home"
            >
              <span className="navIcon navHomeHeath">
                <span className="visually-hidden">Care at Home Icon</span>
              </span>
              <span className="pl-20">Care at Home</span>
            </MenuItem>}
            {canViewCareAtHomeScheduler && <MenuItem
              className={currentPath === CARE_AT_HOME_SCHEDULE ? "active" : ""}
              onClick={() => handleChangeMenu(CARE_AT_HOME_SCHEDULE)}
              title="Care At Home Schedule"
            >
              <span className="navIcon navCareHomeSchedule">
                <span className="visually-hidden">Care At Home Schedule Icon</span>
              </span>
              <span className="pl-20">CAH Schedule</span>
            </MenuItem>}
            <MenuItem
              className={
                currentPath === "/chats/rooms"
                  ? "active"
                  : sidePanel
                    ? "notifications_on"
                    : "notifications_off"
              }
              onClick={() => handleChangeMenu(routes.CHAT)}
              title="Chat"
            >
              <span className="navIcon navChat">
                <span className="visually-hidden">Chat Icon</span>
                {sidePanel && currentPath !== "/chats/rooms" ? (
                  <span className="chat-new-notification">
                    <span className="visually-hidden">
                      New Chat Notification
                    </span>
                  </span>
                ) : (
                  <span> </span>
                )}
              </span>
              <span className="pl-20">Chat</span>
            </MenuItem>
            {canViewAdminChat && <MenuItem
              className={
                currentPath === "/chats-admin/rooms"
                  ? "active"
                  : "notifications_off"
              }
              onClick={() => handleChangeMenu(routes.CHAT_ADMIN)}
              title="Admin Chat"
            >
              <span className="navIcon navAdminChat">
                <span className="visually-hidden">Chat Icon</span>
                <span> </span>
              </span>
              <span className="pl-20">Admin Chat</span>
            </MenuItem>}
            <MenuItem
              className={isOpenReport ? "active" : ""}
              onClick={() => {
                dispatch(setIsOpenReport(true))
              }
                // handleChangeMenu("/report")
              }
              title="Report"
            >
              <span className="navIcon navReports">
                <span className="visually-hidden">Reports Icon</span>
              </span>
              <span className="pl-20">Report</span>
            </MenuItem>
            {canViewAdminPages && <MenuItem
              className={currentPath.startsWith(USERLIST) || window.location.pathname === TERRITORYLIST || window.location.pathname === ZONELIST ||
                window.location.pathname.startsWith(HOSPITALLIST) || window.location.pathname.startsWith(LABLIST) || window.location.pathname.startsWith(FACILITYLIST) ? "active" : ""}
              onClick={() => handleChangeMenu(USERLIST)}
              title="Admin"
            >
              <span className="navIcon navAdmin">
                <span className="visually-hidden">Admin Icon</span>
              </span>
              <span className="pl-20">Admin</span>
            </MenuItem>}
            {canViewGlobalSearch && <MenuItem
              className={currentPath.startsWith(SEARCH) ? "active" : ""}
              onClick={() => handleChangeMenu(SEARCH)}
              title="Global Search"
            >
              <span className="navIcon navGlobalSearch">
                <span className="visually-hidden">Global Search Icon</span>
              </span>
              <span className="pl-20">Global Search</span>
            </MenuItem>}
            <MenuItem
              className={currentPath === USER_GUIDE ? "active" : ""}
              onClick={() => handleChangeMenu(USER_GUIDE)}
              title="User Guide"
            >
              <span className="navIcon navUserGuide">
                <span className="visually-hidden">User Guide Icon</span>
              </span>
              <span className="pl-20">User Guide</span>
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <div className="sidebar-btn-wrapper text-center">
            <a href={USERLIST} className="btn btn-sidebar-btn">
              <img
                src={navlogoicon}
                className="foot-icon"
                alt="aha-mainnav-footer-icon"
                loading="lazy"
              />
              <img
                src={navlogoicontext}
                className="foot-icon-text"
                alt="aha-mainnav-footer-icon-withtext"
                loading="lazy"
              />
            </a>
            <p className="app-version-text">{DESKTOP_APP_VERSION}</p>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </React.Fragment>
  );
}
