import * as React from "react";
import { SEARCH, getURLbyEntity, logout } from "../../app/utils/Constants";
import { CommonHeaderRightSection } from "../../components/commons/CommonHeaderRightSection";
import { getProfile, profileList } from "../LandingPage/Profile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearGlobalSearchResponse, globalSearchLoading, globalSearchResults, searchGlobally, setGlobalSearchError } from "./globalSearchSlice";
import { getFilter } from "../LandingPage/AddUser/filterSlice";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

export const GlobalSearch = () => {
    const dispatch = useDispatch();
    const profile = useSelector(profileList);
    const globalSearchLoader = useSelector(globalSearchLoading);

    const [err, setError] = React.useState({
        value: false, message: ""
    })
    const [name, setName] = React.useState("");
    const [color, setColor] = React.useState("");
    const history = useHistory()

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchInput = searchParams.get("search_input");
    const entities = searchParams.get("entity");
    const globalSearchResultsVal = useSelector(globalSearchResults) || {};
    const { search_result = [] } = globalSearchResultsVal.content || {};

    React.useEffect(() => {
        dispatch(getProfile());
    }, [])

    React.useEffect(() => {
        if (profile && profile.status === "SUCCESS") {
            if (profile && profile.content) {
                const userPermission =
                    profile &&
                    profile.content &&
                    profile.content.permission &&
                    profile.content.permission.filter((val, i) => {
                        return val.page === "users";
                    });
                const uPer =
                    userPermission && userPermission[0] && userPermission[0].access;

                const filter_list =
                    uPer && uPer.find((item) => item === "filter-list") === "filter-list"
                        ? true
                        : false;
                localStorage.setItem(
                    "userDetail",
                    JSON.stringify(profile && profile.content)
                );
                if (filter_list) {
                    dispatch(getFilter());
                }
                setName(profile && profile.content && profile.content.name);
                setColor(profile && profile.content && profile.content.icon_color_code);
                initialize();
            }
        }
    }, [profile]);

    React.useEffect(() => {
        if (globalSearchResultsVal) {
            if (globalSearchResultsVal.status === "SUCCESS") {
                setError({ value: false, message: "" });
            } else if (globalSearchResultsVal.status === "ERROR" && globalSearchResultsVal.message) {
                setError({ value: true, message: globalSearchResultsVal.message });
            }
        }
    }, [globalSearchResultsVal]);

    React.useEffect(() => {
        initialize();
    }, [searchInput, entities])

    React.useEffect(
        () => () => {
            //clear redux state
            dispatch(clearGlobalSearchResponse({}));
            dispatch(setGlobalSearchError({ value: false, message: "" }));
        },
        []
    );

    const initialize = async () => {
        try {
            if (searchInput) {
                let payload = `?search_input=${searchInput}`;
                if (entities) {
                    payload = `${payload}&entity=${entities}`
                }
                dispatch(searchGlobally(payload));
            }
        } catch (err) {
            console.log(err, "error");
        }
    };

    const navigateToURL = (e, url) => {
        e.preventDefault();
        url && history.push(url);
    }

    const highlightSearchText = (text, searchText) => {
        // Create a regular expression with the search text and the global flag
        const regex = new RegExp(`(${searchText})`, 'gi');
        // Replace the matched text with a span having a style for highlighting
        return text.replace(regex, (match, p1) => `<span style="background-color: orange">${p1}</span>`);
      };

    return (
        <React.Fragment>
            <div
                className={"ad-loading-wrapper " + (globalSearchLoader ? "d-block" : "d-none")}
            >
                <span
                    className={"loader ad-loader " + (globalSearchLoader ? "d-block" : "d-none")}
                >
                    <span className="visually-hidden">loader icon</span>
                </span>
            </div>
            <CommonHeaderRightSection
                logout={logout}
                color={color}
                name={name}
                title="Search Results"
                className="d-flex"
            />
            <div style={{height: "calc(100% - 53px)", overflowX: "auto"}}>
                {!searchInput || !entities ?
                    <p className="pt-3">Search something...</p>
                    : (!search_result || !search_result.length) ?
                        <p className="pt-3">No results found</p>
                        : <ol className="gs-list-wrapper">
                            {search_result.map((res, index) => {
                                const { entity, id, entity_label, primary_field } = res;
                                const url = getURLbyEntity(entity, id);
                                const results = Object.keys(res).map(key => { return { fieldName: key, value: res[key] } }).filter(newData => newData.fieldName !== "id" && newData.fieldName !== "entity" && newData.fieldName !== "entity_label" && newData.fieldName !== "primary_field");
                                return (
                                    <li key={`${id}-${index}`} className="mb-3 font-size-13"><a href={url} onClick={e => navigateToURL(e, url)} className="gs-search-link d-inline-block font-size-13 pb-2">{entity_label} - {primary_field}</a>
                                        {results.map(item => {
                                            return (
                                                <span className="d-block font-size-13" dangerouslySetInnerHTML={{ __html: highlightSearchText(`${item.fieldName} - ${item.value}`, searchInput) }} />
                                            )
                                        })}
                                    </li>
                                )
                            })}
                        </ol>
                }
            </div>
            <Modal
                show={err.value}
                onHide={() => {
                    setError({ value: false, message: "" });
                    if (searchInput && entities) {
                        history.push(SEARCH, { shallow: true });

                    }
                }}
                className="session-modal network-modal"
                backdrop="static"
                backdropClassName="network-error-backdrop"
                enforceFocus={false}
            >
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title className="headline4 text-start d-flex justify-content-start align-items-center pe-2">
                        {" "}
                        <span className="network-error-icon me-3 pt-1 d-flex"></span>
                        <p className="network-error-text">{err.message}</p>
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </React.Fragment>
    );
};